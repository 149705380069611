import GraphicBsquatMasculineAndJuvenil from "@/views/gym-rating/components/bsquat/graphicBsquatMasculine/graphicBsquatMasculine.vue"
import GraphicBrenchPressMasculineAndJuvenil from "@/views/gym-rating/components/brenchPress/graphicBrenchPressMasculine/graphicBrenchPressMasculine.vue"
import GymRatingGraphicAvgStudentBrenchPress from '@/components/layout/GymRatingGraphicAvgStudentBrenchPress/gymRatingGraphicAvgStudentBrenchPress.vue'
import GymRatingGraphicAvgStudentDeadLift from '@/components/layout/GymRatingGraphicAvgStudentDeadLift/gymRatingGraphicAvgStudentDeadLift.vue'
import GymRatingGraphicAvgStudentClean from '@/components/layout/GymRatingGraphicAvgStudentClean/gymRatingGraphicAvgStudentClean.vue'
import GymRatingGraphicAvgStudentJump from '@/components/layout/GymRatingGraphicAvgStudentJump/gymRatingGraphicAvgStudentJump.vue'
import GraphicBrenchPressFeminine from "@/views/gym-rating/components/brenchPress/graphicBrenchPressFeminine/graphicBrenchPressFeminine.vue"
import GraphicDeadLiftMasculineAndJuvenil from "@/views/gym-rating/components/deadLift/graphicDeadLiftMasculine/graphicDeadLiftMasculine.vue"
import GymRatingGraphicAvgStudentBsquat from '@/components/layout/GymRatingGraphicAvgStudentBsquat/gymRatingGraphicAvgStudentBsquat.vue'
import GraphicCleanMasculineAndJuvenil from "@/views/gym-rating/components/clean/graphicCleanMasculine/graphicCleanMasculine.vue"
import GraphicDeadLiftFeminine from "@/views/gym-rating/components/deadLift/graphicDeadLiftFeminine/graphicDeadLiftFeminine.vue"
import GraphicBsquatFeminine from "@/views/gym-rating/components/bsquat/graphicBsquatFeminine/graphicBsquatFeminine.vue"
import GraphicCleanFeminine from "@/views/gym-rating/components/clean/graphicCleanFeminine/graphicCleanFeminine.vue"
import ClassRegistrationRepository from '@/shared/http/repositories/socialProject/classregistration'
import GymRatingReportRepository from '@/shared/http/repositories/socialProject/gymRating-report'
import GraphicJump from "@/views/gym-rating/components/jump/graphicJump/graphicJump.vue"
import ReportGymRatingCriteria from '@/shared/models/criteria/studentgymratingcriteria'
import TeacherRepository from '@/shared/http/repositories/socialProject/teacher'
import ClassRepository from '@/shared/http/repositories/socialProject/class'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import fab from 'vue-fab'

export default {
  components: {
    GraphicBsquatMasculineAndJuvenil,
    GraphicBsquatFeminine,
    GraphicBrenchPressMasculineAndJuvenil,
    GraphicBrenchPressFeminine,
    GraphicDeadLiftMasculineAndJuvenil,
    GraphicDeadLiftFeminine,
    GraphicCleanMasculineAndJuvenil,
    GraphicCleanFeminine,
    GraphicJump,
    GymRatingGraphicAvgStudentBrenchPress,
    GymRatingGraphicAvgStudentBsquat,
    GymRatingGraphicAvgStudentDeadLift,
    GymRatingGraphicAvgStudentClean,
    GymRatingGraphicAvgStudentJump,
    [BreadCrumb.name]: BreadCrumb,
    fab
  },

  data: () => ({
    criteria: new ReportGymRatingCriteria(),
		showGraphicAvgStudent: false,
    onCheckFeminineClass: false,
    showDescriptions: true,
		showGraphics: false,
    haveFeminine: false,
    gymRatingId: null,
    selectedByStudent: [],
		optionsTeachers: [],
		selectedByClass: [],
		optionsClass: [],
		students: [],
    student: [],
    colsNumber: null,
		onTabName: 'yieldByClass',
    emptyText: 'Faça uma pesquisa para preencher esta tabela',
		fieldsByClass: [
			{
				key: 'seasonName',
				label: 'NOME DA TEMPORADA',
        class: 'left-header-border text-center'
			},
			{
        key: 'className',
        label: 'NOME DA TURMA',
        class: 'middle-header text-center'
      },
			{
        key: 'category',
        label: 'CATEGORIA',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'season',
        label: 'ANO DA TEMPORADA',
        class: 'right-header-border text-center',
        sortable: true
      }
		],
    fieldsByStudent: [
      {
        key: 'studentName',
        label: 'NOME DO ALUNO',
        class: 'left-header-border text-center'
      },
      {
        key: 'seasonName',
        label: 'NOME DA TEMPORADA',
        class: 'middle-header text-center'
      },
      {
        key: 'className',
        label: 'NOME DA TURMA',
        class: 'middle-header text-center'
      },
      {
        key: 'className',
        label: 'NOME DA TURMA',
        class: 'middle-header text-center'
      },
      {
        key: 'category',
        label: 'CATEGORIA',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'season',
        label: 'ANO DA TEMPORADA',
        class: 'right-header-border text-center',
        sortable: true
      },
    ],
		optionsCategory: [
      {value: 'JUNIOR E SENIOR', text: 'JUNIOR E SENIOR'},
      {value: 'JUVENIL I', text: 'JUVENIL I'},
      {value: 'JUVENIL II', text: 'JUVENIL II'},
    ],
    pagination: {
      count: 1,
      limit: 10,
      index: 1
    }
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Relatórios',
          },
					{
            text: 'Academia',
            active: true
          }
        ]
      }
    }
  },

	created() {
    this.getAllTeacher()
  },

  watch: {
    onCheckFeminineClass: {
      handler: function (value) {
        if (value == true) {
          this.onHasFeminineInClass()
        }
      },
      immediate: true
    }
  },

  methods: {
    onHasFeminineInClass() {      
      const category = this.selectedByClass.category

      if (category != 'JUNIOR E SENIOR'){
        this.colsNumber = '12'
        this.onCheckFeminineClass = false
        return
      } 
      
      loading.push()
      ClassRegistrationRepository.GetByClass(this.selectedByClass.classId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          const studentClass = res.data.data
          const hasFeminine = studentClass.some(x => x.studentGender == 'Feminino')
          
          this.onCheckFeminineClass = false

          if (hasFeminine) {
            this.colsNumber = '6'
            this.haveFeminine = true
          }
          
          if (!hasFeminine) {
            this.colsNumber = '12'
            this.haveFeminine = false
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao verificar alunos', 'ERRO')
        })
    },

		checkTabName() {
      if (this.onTabName == 'yieldByClass') {
        this.clearYieldByStudent()
        this.getAllByClass()
      } else if (this.onTabName == 'yieldByStudent') {
        this.clearYieldByClass()
        this.getAllByStudent()
      }
    },

		onSetTabName(tab) { this.onTabName = tab },

		getAllByStudent() {
      const studentName = this.criteria.studentName
      
      if (studentName == null || studentName.trim() == '') {
        toast.info('Insira o nome de algum aluno', 'AVISO')
        return
      }

      loading.push()
      GymRatingReportRepository.GetByStudentName(this.criteria)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.student = res.data.data

          if (this.student.length == 0) {
            toast.info('Nenhum registro encontrado', 'AVISO')
            this.gymRatingId = null
            this.showGraphicAvgStudent = false
            this.selectedByStudent= []
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao pegar alunos', 'ERRO')
        })
    },

    getAllByClass() {
      loading.push()
      GymRatingReportRepository.GetAll(this.criteria, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.students = res.data.data

          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }

          if (this.students.length == 0) {
            toast.info('Nenhum registro encontrado', 'AVISO')
            this.gymRatingId = null
            this.showGraphics = false
            this.selectedByClass= []
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao pegar alunos', 'ERRO')
        })
    },

		getClass() {
      if (!this.criteria.teacherId) {
        this.optionsClass = []
        return
      }

      loading.push()
      ClassRepository.GetAll(this.criteria)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.optionsClass = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch((err) => {
          loading.pop()
          console.log(err)
          toast.error(err, 'ERRO')
        })
    },

		getAllTeacher() {
      loading.push()
      TeacherRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.optionsTeachers = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar professores', 'ERRO')
        })
    },

		rowSelectedClass(item) {
      loading.push()
			this.showGraphics = false
			this.selectedByClass = item
      this.onCheckFeminineClass = true
      setTimeout(() => {
        this.showGraphics = true
        loading.pop()
      }, 2500)
			this.showDescriptions = false
      this.gymRatingId = item.id
		},

    rowSelectedStudent(item) {
			this.selectedByStudent = item
			this.showGraphicAvgStudent = true
      this.gymRatingId = item.id
		},

    clearYieldByClass() {
      this.showDescriptions = true
      this.showGraphics = false
      this.gymRatingId = null
      this.selectedByClass = []
      this.students = []
    },
    
    clearYieldByStudent() {
      this.showGraphicAvgStudent = false
      this.selectedByStudent = []
      this.student = []
      this.gymRatingId = null
      this.criteria.studentName
    }
  }
}
